import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router";
import { initWs, loginUserAc } from "../../redux/actions/opening/action";

import { getInvitations, login, setAnyTokenInSessionStorage, setTokenInLocalStorage } from "../../lib/api";

import { LOGIN_TITLE, LOGIN_TOKEN, SOCKET_CLIENT_ID } from "../../constant";
// import BackgroundImage from "./BackgroundImage";
// import { tokenVerify } from "../../service/tokenVerify";
import { loginUser } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

import { Alert, Button, Divider, Form, Input, Typography } from "antd"
import Link from "antd/es/typography/Link"

const { Text } = Typography
type props = {
  loginUserAc(token: any, userData: any): void;
  loginUser: loginUser;
  applicationId: any;
  applicationName: string;
  initWs: any;
};
class Login extends Component<props & RouteComponentProps> {
  state = {
    loading: false,
    errorMessage: "", // Add a state to store the error message
    // Add loading state
  };
  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // if user is already logged in,and user have a org created then redirecting user to orgSwitcher
  componentDidMount = () => {
    document.title = `${LOGIN_TITLE}`;
  };



  handleSubmit(event: any) {

    this.setState({ loading: true, errorMessage: "" })

    const loginObject = {
      loginId: event.email.toLowerCase(),
      password: event.password,
    };
    login(loginObject).then((loginRes) => {
      setTokenInLocalStorage(LOGIN_TOKEN, loginRes.token).then(async(flag: any) => {
        this.props.loginUserAc(loginRes.token, loginRes.user);
        this.setState({ loading: false });

        setAnyTokenInSessionStorage(SOCKET_CLIENT_ID,loginRes.user.id+"_"+new Date().getTime()).then(()=>{return true})
        if (flag) {
          getInvitations(loginRes.user.email).then((invitations: any) => {
            const { verified, registrations } = loginRes.user;
        
            const hasInvitations = invitations?.length > 0;
            const hasRegistrations = registrations && registrations.length !== 0;

            if (verified && (hasInvitations || hasRegistrations)) {
              return this.props.history.push("/o");
            }
              return this.props.history.push("/onboarding");
          });
        }
        
      });
    }).catch((error) => {
      if (error.response && error.response.status === 400 || error.response.status === 404) {
        this.setState({ errorMessage: "Invalid credentials. Please check your email or password." })
      }
      this.setState({ loading: false })
    });
  }

  render() {
    return (
      <>
        <div className="flex flex-col md:space-y-4 space-y-2 relative w-full ">
          <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
            {capitalizeFirstLetter("Sign in")}
          </div>
          {this.state.errorMessage && (
            <Alert message={this.state.errorMessage} type="error" />
          )}

          <Form
            name="normal_login"
            className="login-form text-xs w-full"
            initialValues={{ remember: true }}
            onFinish={this.handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Work email"
              rules={[
                { required: true, message: "Please input your work email!" },
                { type: 'email', message: 'Invalid email format' },
              ]}
              className=" font-medium"
            >
              <Input placeholder="abc@example.com" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              className=" font-medium"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Link href="/password/change">Forgot password?</Link>
            <Form.Item>
              <Button
                loading={this.state.loading} // Use the loading prop
                type={"primary"}
                htmlType="submit"
                className=" w-full my-3"
              >
                Sign in
              </Button>
            </Form.Item>
            <div className=" ">
          <div className=" font-normal text-sm ">
            By signing up, you agree to our&nbsp;
            <a
           
              href="https://www.tacitbase.com/terms-of-use"
              style={{ color: "#009D79" }}
            >
              terms of use{" "}
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.tacitbase.com/privacy"
              style={{ color: "#009D79" }}
            >
              {" "}
              privacy policy.
            </a>
            &nbsp;Need help? Visit&nbsp;
            <a
              href="https://support.tacitbase.com/"
              style={{ color: "#009D79" }}
            >
              support.
            </a>
          </div>
        </div>
            <Divider></Divider>
            <div className=" mt-3">
              <Text>Not a member?</Text> <Link href="/register">Sign up</Link>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  loginUserAc: (token: any, userData: any) =>
    dispatch(loginUserAc(token, userData)),
  initWs: (ws: WebSocket) => dispatch(initWs(ws)),
});
export default connect(mapStateToProps, mapPropsTostate)(withRouter(Login));
