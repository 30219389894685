import React from "react";
import AddMember from "./components/cardActions/addMember/AddMember";
import LabelPopup from "./components/cardActions/addLabel/LabelPopup";
import MoveCardPopup from "./components/cardActions/moveCard/MoveCardPopup";
import CopyCardPopup from "./components/cardActions/copyCard/CopyCardPopup";
import AddAttachment from "./components/cardActions/addAttachment/AddAttachment";
import ArchiveIcon from "./assets/Archive";
import LabelsIcon from "./assets/Labels";
import CardCopyIcon from "./assets/CardCopy";
import AttachmentIcon from "./assets/Attachment";
import MoveCardIcon from "./assets/MoveCard";
import SetImgIcon from "./assets/SetImage";
import MembersSmallIcon from "./assets/MembersSmall"
import { TableColumn, TabType } from "./type/type";
import CreateReview from "./components/candidateDB/BulkActions/CreateReview";
import AddComment from "./components/candidateDB/BulkActions/AddComment";
import AddLabel from "./components/candidateDB/BulkActions/AddLabel";
import CalenderIcon from "./assets/CalendarHoverable";
import { Role } from "./lib/apiRes";
import Calender from "./assets/Calender";
import meet from "./images/meet.png";
import zoom from "./images/zoom.png"
import SendToBoard from "./components/candidateDB/BulkActions/SendToBoard"
import Inbox from "./assets/inbox";
import Sent from "./assets/Sent";
import { BankOutlined } from "@ant-design/icons";
import { Typography } from "antd";
// import ShowMemberInTable from "./components/analytic/ShowingMembersInTable";
// import ShowMemberName from "./components/analytic/ShowMemberName";
const { Text } = Typography;
import {
  SolutionOutlined
} from "@ant-design/icons"
import Facebook from "./assets/Facebook";
import Twitter from "./assets/Twitter";
import Linkedin from "./assets/Linkedin";
import Github from "./assets/Github";
import Figma from "./assets/Figma";
import Dribbble from "./assets/Dribbble";
import PersonalBlog from "./assets/PersonalBlog";
import Leetcode from "./assets/Leetcode";
import Behance from "./assets/Behance";
import ResearchGate from "./assets/ResearchGate";
import GoogleScholar from "./assets/GoogleScholar";
import StackOverflow from "./assets/StackOverFlow";

//common
export const TITLE = "Title";
export const SHOW_MORE = "Show more";
export const SHOW_LESS = "Show less";
export const PHOTOS = "Photos";
export const DETAILS = "Details";
export const PHOTOS_UNSPLASH = "Photos by Unsplash";
export const COLORS = "Colors";
export const SELECT_DESTINATION = "SELECT DESTINATION";
export const ARE_YOU_SURE = "Are you sure you want to reward this card?";
export const DATE_TIME_FORMATE = "D MMM [at] h:mm a";
export const DATE_FORMATE = "D MMM yyyy ";
export const TIME_FORMATE = "h:mm a";
export const PAGE_NOT_FOUND = "The page you are looking for is not here";
export const SEARCH_KEY_NOT_FOUND_IN_REWARD =
  "The search key is not found in any reward";
export const BACK_TO_LOGIN = "Return to login";

//board
export const CREATE_BOARD = "Create new board";
export const TEAM_DASHBOARD = "Team Dashboard";
export const INDIVIDUAL_DASHBOARD = "My Dashboard";
export const FETCHING_BOARDS = "Fetching job posts";


// Notification Msgs constants
export const ATTRIBUTE_ADDED = "added";
export const ATTRIBUTE_CARD = "card";
export const ATTRIBUTE_MOVED = "moved";
export const ATTRIBUTE_ATTACHMENT = "attachment";
export const ATTRIBUTE_UNARCHIVED = "unarchived";
export const ATTRIBUTE_ARCHIVED = "archived";
export const ATTRIBUTE_LIST = "list";
export const ATTRIBUTE_LABEL = "label";
export const ATTRIBUTE_CREATED = "created";
export const ATTRIBUTE_BOARD = "board";
export const ATTRIBUTE_CREDITED = "credited";
export const ATTRIBUTE_REWARD = "reward";
export const ATTRIBUTE_DEBITED = "debited";
export const ATTRIBUTE_MEMBER = "member";
export const ATTRIBUTE_DELETED = "deleted";
export const ATTRIBUTE_UPDATED = "updated";
export const ATTRIBUTE_COMMENT = "comment";
export const ATTRIBUTE_REMOVED = "removed";
export const ATTRIBUTE_CANDIDATE = "candidate";
export const ATTRIBUTE_BILLING = "billing";
export const ATTRIBUTE_REVIEW = "review";
export const ATTRIBUTE_CLOSED = "closed";
export const ATTRIBUTE_EVENT = "event";
export const ATTRIBUTE_CANCELLED = "cancelled";
export const ATTRIBUTE_SENT = "sent";
export const ATTRIBUTE_REPLIED = "replied";
export const ATTRIBUTE_EMAIL = "email";

// Section
export const EMAIL_EXIST =
  "Email already taken. Please login or try another email";
export const STARRED = "Starred Board";
export const RECENTLY = "Recently Viewed";
export const LOGIN_HEADING = "sign in to your account";
export const REQUEST_SIGNUP = "request sign up";
export const FORGET_PASSWORD = "lost your password";
export const WORKSPACE = "Workspace";

//SideNav

//BoardBackground
export const HEADER_TITLE = "Board background";

//Navbar
export const NAV_TEXTBOX_PLACEHOLDER = "Search...";

//Textbox
export const SEARCH_PLACEHOLDER = "Find JD by name...";

//cardNameinput
export const CREATE_CARD = "Enter Card Title";

//BoardModal
export const DARK = "dark";
export const LIGHT = "light";

export const COLOR_DATA = [
  {
    colorCode: "red text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "green text-white",
    isSet: true,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "blue text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "orange text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "magenta text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "teal text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "maroon text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "brown text-white ",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "navy text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "grey text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },

  {
    colorCode: "Pink text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Lavender text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Beige text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Mint text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Cyan text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Yellow text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
];

export const colorDictionary: { [key: string]: string } = {
  red: "#E6194B",
  green: "#3CB44B",
  blue: "#4363D8",
  orange: "#F58231",
  magenta: "#F032E6",
  teal: "#469990",
  brown: "#9A6324",
  maroon: "#800000",
  navy: "#000075",
  grey: "#A9A9A9",
  black: "#000000",
  Yellow: "#FFE119",
  Pink: "#FABED4",
  Lavender: "#DCBEFF",
  Beige: "#FFFAC8",
  Mint: "#AAFFC3",
  Cyan: "#42D4F4",
  LightGray: "#f1f1f6"
};


//Colours for Analytics Labels

export const colorDictionary1: { [key: string]: string } = {
  blue:"#1890FF",
  greekblue:"#2F54EB",
  purple:"#722ED1",
  green:"#52C41A",
  volcano:"#FA541C",
  orange:"#FA8C16",
  pink:" #EB2F96 ",
  cyan:"#13C2C2",
  gold:"#FAAD14  ",
  lime:" #A0D911",
  red:"#F5222D ",
  magenta:"#252525",
};

// let COLOR_DATA_LABEL:any[]= [];
export const COLOR_DATA_LABEL: any[] = [
  {
    colorCode: "red text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "green text-white",
    isSet: true,
    showMore: false,
  },
  {
    colorCode: "blue text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "orange text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "magenta text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "teal text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "maroon text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "brown text-white ",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "navy text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "grey text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "black text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Pink text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Lavender text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Beige text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Mint text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Cyan text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Yellow text-black",
    isSet: false,
    showMore: false,
  },
];

export const COLOR_DATA_EVENT: any[] = [
  {
    colorCode: "#9F2757",
    isSet: false,
    showMore: false,
    name: "Radicchio",
  },
  {
    colorCode: "#74574A",
    isSet: true,
    showMore: false,
    name: "Cocoa",
  },
  {
    colorCode: "#5383EC",
    isSet: false,
    showMore: false,
    name: "Cobalt",
  },
  {
    colorCode: "#88B253",
    isSet: false,
    showMore: false,
    name: "Pistachio",
  },
  {
    colorCode: "#C63961",
    isSet: false,
    showMore: false,
    name: "Cherry",
  },
  {
    colorCode: "#397E49",
    isSet: false,
    showMore: false,
    name: "Basil",
  },
  {
    colorCode: "#C3291C",
    isSet: false,
    showMore: false,
    name: "Tomato",
  },
  {
    colorCode: "#C2CA51",
    isSet: false,
    showMore: false,
    name: "Avocado",
  },
  {
    colorCode: "#E39735",
    isSet: false,
    showMore: false,
    name: "Mango",
  },
  {
    colorCode: "#469990",
    isSet: false,
    showMore: false,
    name: "Teal",
  },
  {
    colorCode: "#800000",
    isSet: false,
    showMore: false,
    name: "Maroon",
  },
  {
    colorCode: "#9A6324",
    isSet: false,
    showMore: false,
    name: "Brown",
  },
  {
    colorCode: "#000075",
    isSet: false,
    showMore: false,
    name: "Navy",
  },
  {
    colorCode: "#A9A9A9",
    isSet: false,
    showMore: false,
    name: "Grey",
  },
  {
    colorCode: "#000000",
    isSet: false,
    showMore: false,
    name: "Black",
  },
  {
    colorCode: "#FABED4",
    isSet: false,
    showMore: false,
    name: "Pink",
  },
  {
    colorCode: "#DCBEFF",
    isSet: false,
    showMore: false,
    name: "Lavender",
  },
];
export const IMAGE_DATA = [
  {
    colorCode: "BackToSchool",
    isSet: true,
    showMore: false,
  },

  {
    colorCode: "FerrisWheel",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Spain",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "OilInWater",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Cat",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "SpringMagic",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Renders3D",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "SeaShell",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Sunflower",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Road",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Mars",
    isSet: false,
    showMore: false,
  },
];

//list
export const ADD_CARD = "Add Card";

//createList
export const ADD_LIST = "Add List";

//listNameInput
export const INPUT_PLACEHOLDER = "Enter List Title";

export const MENU = "menu";

//CardPreviewSeideSection menu
export const ACTION = "ACTION";
export const CHANGE_VISIBILITY = "visibility";
export const SLACK = "slack";
export const SLACK_APP_CONNECTION_WARNING = "Please integrate your Slack app."
export const CONNECT_SLACK_ACCOUNT = "connect slack user"

export const ADD_TO_CARD = "Add to card";
export const REWARD = "Reward";
export const EVENTS = "Events";
export const MEMBERS = "members";
export const LABELS = "labels";
export const ATTACHMENTS = "Attachments";

/******************************** Websocket topic **************************************************/
export const WS_CDB = "cdb";
export const WS_BILLING = "billing";
export const WS_SETTING = "orgsetting";
export const WS_REVIEW = "review";
export const WS_ORG = "org";

/******************************** Sidenav menu  ***************************************/

export const SIDENAV_MENU_ITEMS = {
  UPDATES: "Updates",
  ANALYTICS: "Analytics",
  BOARDS: "Job posts",
  TALENT_POOL: "Talent pool",
  REVIEW: "Reviews",
  INBOX: "Mails",
  IMPORT: "Import",
  MEMBERS: "Members",
  JOB_DESCRIPTION: "Job description ",
  WORKSPACE: "Workspace",
};
export const titleMapping: { [key: string]: string } = {
  db: "Talent Pool",
  analytics: "Analytics",
  tmail: "Mails",
  reviews: "Review",
  ats: "Job Posts",
  updates: "Updates",
  o: "Organizations",
  "start-setup": "Organization setup",
  createOrg: "Create org"
};


export const CARD_PREVIEW_MENU = [
  {
    name: "Members",
    icon: <MembersSmallIcon />,
    active: true,
    popup: AddMember,
  },
  { name: "Labels", icon: <LabelsIcon />, active: true, popup: LabelPopup },
  {
    name: "Attachments",
    icon: <AttachmentIcon />,
    active: true,
    popup: AddAttachment,
  },
];


export const CARD_EVENTS_MENU = [
  {
    name: "Schedule an event",
    icon: <CalenderIcon />,
    active: true,
  },
];
export const COMPOSE_EMAIL = [
  {
    name: "compose mail",
    icon: <Sent />,
    active: true,
  },
];


export const ACTION_MENU = {
  ul: [
    {
      name: "Move",
      icon: <MoveCardIcon />,
      active: true,
      popup: MoveCardPopup,
    },
    {
      name: "Copy",
      icon: <CardCopyIcon />,
      active: true,
      popup: CopyCardPopup,
    },
  ],
  ul2: [
    {
      name: "Archive",
      icon: <ArchiveIcon />,
      active: true,
    },
  ],
  ul3: [
    {
      name: "Send To Tracker",
      icon: <ArchiveIcon />,
      active: true,
      popup: AddMember,
    },
    { name: "Delete", icon: <ArchiveIcon />, active: true, popup: AddMember },
  ],
};

//card
export const NOTIFY_ARCHIVE = "This card is archived";

//CardPreviewDatesBadge
export const DATES = "dates";
export const CDBMember = "CDBMember";
export const JDMember = "JDMember";

//BillingActionNames
export const billingMember = "billingMember";
export const companyMember = "companyMember";

//cardDescription
export const DESCRIPTION = "Description";
export const DETAIL_DESC = "Add a more detailed description…";

//cardPreviewDatebadge
export const DUE_SOON = "due soon";
export const COMPLETE = "complete";

//listPopup
export const ListMenuItem = {
  ul1: [
    // { name: "Add Menu" },
    // { name: "copy list", popup: CopyList },
    { name: "Move", popup: "MoveList" },
    { name: "Archive list", popup: "ArchiveList" },
  ],
  ul2: [
    { name: "Move all cards in this list", popup: "MoveAllCardsInList" },
    { name: "Archive all cards in this list", popup: "ArchiveAllCard" },
  ],
};

//profilePopup
export const profileMenuItem = {
  ul1: [
    { name: "Profie and Visibility" },
    { name: "Activity" },
    { name: "Cards" },
    { name: "Settings" },
  ],
  ul2: [{ name: "Help" }, { name: "Shortcut" }],
  ul3: [{ name: "Logouts" }],
};

export const PlansName = {
  premium:"premium",
  starter:"starter",
  free:"free",
}

//Boardmenu
export const BOARD_MORE_ITEMS = [
  // { name: "Settings", component: "setting", icon: <SettingSmallIcon /> },
  { name: "Labels", component: "BoardLabel", icon: <LabelsIcon /> },
  {
    name: "Archived items",
    component: "ArchiveItems",
    icon: <ArchiveIcon />,
  },
];
export const BOARD_MENU = [
  // {
  //   name: "About this board",
  //   component: "BoardDescription",
  //   subTitle: "Add a description to your board",
  //   icon: <BoardIcon />,
  // },
  {
    name: "Change background",
    component: "ChangeBoardBackground",
    icon: <SetImgIcon />,
  },
  // {
  //   name: "Search Cards",
  //   component: "SearchCard",
  //   icon: <SearchIcon />,
  // },

  { name: "Labels", component: "BoardLabel", icon: <LabelsIcon /> },
  {
    name: "Archived items",
    component: "ArchiveItems",
    icon: <ArchiveIcon />,
  },
  {
    name: "Calendar settings",
    component: "CalenderSettings",
    icon: <Calender />,
  },
  {
    name: "Workspace",
    component: "AttachCompany",
    icon: <BankOutlined   />,
  },
  {
    name: "Public URL",
    component: "JdLink",
    icon: <SolutionOutlined/>,
  },
];

export const ReminderTags = [
  "None",
  "5 Minutes before",
  "10 Minutes before",
  "30 Minutes before",
  "1 Hour before",
  "2 Hours before",
  "1 Day before",
  "2 Days before",
];

export const ADD_MEMBER_TITLE = "add member";
export const ADD_CANDIDATE = "add candidate";
export const ADD_REVIEWER = "add reviewer";
export const ADD_COMPANY = "add workspace";

//search result
export const SEARCH_TO_ADD_MEMBER =
  "Looks like that person isn't a org member yet.";

export const SEARCH_IN_BOARD_MEMBER = "This person is not a member of board.";
export const SEARCH_IN_CDB_MEMBER =
  "This person is not a member of candidate database.";
export const SEARCH_IN_BILLING_MEMBER =
  "This person is not a member of billing.";
export const SEARCH_RESULT = "No result found";
// Comment
export const COMMENT_TAG = "comment";
export const ACTIVITY_TAG = "activity";
export const EMAIL_TAG = "email";

//custom inline error messages
export const ALREADY_LOGIN_IN_ANOTHER_TAB =
  "you are already login in somewere, please refresh the page";
export const SERVER_DOWN =
  "We are unable to process your request, please try again later";
export const SUCCESS_RESET_PASSWORD = "Password changed successfully.";
export const EMAIL_EMPTY = "Email is required.";
export const PASSWORD_EMPTY = "Password is required.";
export const FIRST_NAME_EMPTY = "First name is required.";
export const USER_NAME_EMPTY = "Username is required.";
export const INVALID_EMAIL = "Invalid e-mail address. Please try again.";
export const ACCOUNT_NOT_FOUND =
  "No account associated with this email. Try signing up.";
export const EMAIL_PASSWORD_INCORRECT = "The email or password is incorrect.";
export const ORG_EXIST = "Name already exist, try another name.";
export const LAST_NAME = "Last name is required.";
export const NO_SPACE_ALLOWED = "Space not allowed.";
export const USERNAME_EXIST = "Username already exist, try another name.";
export const ACCOUNT_EXIST = "Account already exist";
export const MAX_CHAR_ALLOWED = "Max. characters limit is 50.";
export const MAX_ATTACHMENT_SIZE =
  "The size of the attachment exceeds the allowed limit of 10MB.";
export const SAME_PASSWORD = "Password does not match.";
export const PASSWORD_INVALID =
  "Required:12 char, 1 uppercase,1 lowercase, 1 special Char, 1 digit";
export const ACTIVATE_MEMBER_CONFIRMATION =
  "Great! Do you want to activate a member in the organization?";
export const DEACTIVATE_MEMBER_CONFIRMATION =
  "Are you sure? you are about to deactivate a member from the organization. This member will be unable to login the Tacitbase.";
export const MAX_CHAR_LIMIT_WARNING = "maximum character limit is 250";
export const MAX_RECIPIENT_WARNING =
  "The maximum number of recipients allowed is 25, but you have exceeded this limit. Please reduce the number of recipients to 25 or fewer.";
export const NO_LIST_FOUND_IN_BOARD = "No List found";
export const FORM_SAVE = "Form saved successfully.";
export const DISCONNECT_SLACK_USER = "Are you sure you want to disconnect Slack integration? This action may disrupt current workflows."
//toast notification
export const SLACK_INTEGRATION = "Slack settings integrated successfully.";
export const UPDATE_SLACK_OPTIONS = "Slack settings updated successfully.";
export const DELETE_SLACK_OPTIONS = "Slack settings deleted successfully.";
export const SLACK_TOKEN_EXPIRE_WARNING = "Your token has expired, and notifications are now paused. Please reconnect the Tacitbase app to your Slack workspace."
export const COPY_CARD = "Card copied to the selected board.";
// Token
export const LOGIN_TOKEN = "login_token";

// Socket client ID
export const SOCKET_CLIENT_ID="socket_client_id";
//title
export const TACITBASE = "Tacitbase";

export const AVTAR_TITLE = `Avtar | ${TACITBASE}`;
export const LOGIN_TITLE = `Login | ${TACITBASE}`;
export const REGISTRATION_TITLE = `Registration | ${TACITBASE}`;
export const VERIFICATION_TITLE = `Verification | ${TACITBASE}`;
export const PAGE_NOT_FOUND_TITLE = `Page not found | ${TACITBASE}`;
export const ORGANISATION_TITLE = `Organisations | ${TACITBASE}`;

// roles
export const GUEST = "GUEST";
export const OWNER = "Owner";
export const ADMIN = "ADMIN";
export const MANAGER = "manager";
export const RECRUITER = "recruiter";

export const roles = {
  COMMENTOR: "Commentor",
  MEMBER: "Member",
  ADMIN: "Admin",
};

export const DEACTIVATE_BUTTON = "Deactivate";
export const ACTIVATE_BUTTON = "Activate";

//sorting typrs
export const ASCENDING = "ASCENDING";
export const DESCENDING = "DESCENDING";
export const DATE = "DATE";

//formdata
export const TALENT_PARTNER = "TALENT PARTNER";
export const HR = "HR";
export const HM = "HM";
export const MEMBER_ONLY = "member only";
export const PUBLIC = "public";

//search box placehlder
export const SEARCH_LABEL_PLACEHOLDER = "Search label";
export const SEARCH_MEMBER_PLACEHOLDER = "Search member";

export const UPLOAD_FROM_COMPUTER = "Upload from computer";
export const JD_MANAGEMENT = "job description management ";
export const CANDIDATE_DATABASE = "TALENT POOL";
export const BULK_IMPORT_CANDIDATES = "BULK IMPORT CANDIDATES";
export const CLIENT_CREATION = "WORKSPACE";
export const JOB_POSTS = "job posts";

export const TABLE_HEADER: TableColumn[] = [
  {
    name: "first",
    type: "character varying",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "last",
    type: "character varying",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "description",
    type: "character varying",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "email",
    type: "character varying",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "phone",
    type: "character varying",
    secret: false,
    custom: false,
    view: "",
  },
];
export const QUICK_TABLE_FIELDS: TableColumn[] = [
  {
    name: "first_name",
    type: "string",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "last_name",
    type: "string",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "email",
    type: "string",
    secret: false,
    custom: false,
    view: "",
  },
  {
    name: "phone",
    type: "string",
    secret: false,
    custom: false,
    view: "",
  },
];

export const BULK_ACTION_ON_TABLE: TabType = {
  "Add label": {
    active: false,
    component: AddLabel,
    icon: <LabelsIcon fill={"#fff"} />,
  },
  "Send to tracker": {
    active: false,
    component: SendToBoard,
    icon: <LabelsIcon fill={"#fff"} />,
  },
  "send to review": {
    active: false,
    component: CreateReview,
    icon: <LabelsIcon fill={"#fff"} />,
  },
};
export const BULK_ACTION_ON_CANDIDATE = {
  comments: {
    active: false,
    component: AddComment,
    icon: <LabelsIcon fill={"#fff"} />,
  },
  // "send email": {
  //   active: false,
  //   component: CreateReview,
  //   icon: <LabelsIcon fill={"#fff"} />,
  // },
};


export const FILTER_OPERATORS: any = [
  { name: "string", operators: ["start with", "equals"] },
  { name: "number", operators: ["is less than", "is greator than"] },
  // { name: "date", operators: ["between"] },
  // { name: "paragraph", operators: ["between"] },
  { name: "url", operators: ["between"] },
  // { name: "file", operators: ["between"] },
  { name: "email", operators: ["between"] },
];
export const COLUMN = "Column";
export const OPERATOR = "Operator";

export const LESS_THAN = "less than";
export const GREATOR_THAN = "greater than";
export const LESS_THAN_EQUAL = "less than or equal to";
export const GREATOR_THAN_EQUAL = "greater than or equal to";
export const IS = "is";

export const operatorMap: { [key: string]: string } = {
  [IS]: "=",
  [LESS_THAN_EQUAL]: "<=",
  [LESS_THAN]: "<",
  [GREATOR_THAN_EQUAL]: ">=",
  [GREATOR_THAN]: ">",
  // Add more mappings as needed
};
type FilterOperator =
  | typeof IS
  | "<"
  | ">"
  | "="
  | "<="
  | ">="
  | "before"
  | "after"
  | typeof LESS_THAN
  | typeof GREATOR_THAN
  | typeof GREATOR_THAN_EQUAL
  | typeof LESS_THAN_EQUAL;

export type FilterOperatorObject = { name: FilterOperator };

type FilterOperators = Record<string, FilterOperatorObject[]>;

export const filterOperators: FilterOperators = {
  string: [{ name: IS }],
  number: [
    { name: LESS_THAN },
    { name: GREATOR_THAN },
    { name: IS },
    { name: LESS_THAN_EQUAL },
    { name: GREATOR_THAN_EQUAL },
  ],
  boolean: [{ name: IS }],
  date: [{ name: "before" }, { name: "after" }],
  url: [{ name: IS }],
  email: [{ name: IS }],
  radio: [{ name: IS }],
  paragraph: [{ name: IS }],
  phone: [{ name: IS }],
  // Add more types and operators as needed
};

export const JDTableHead = [
  "Title",
  "Attached tracker",
  "status",
  "created by",
  "updated on",
  // "quick actions",
];

export const FormBuilderTableHead = [
  "Title",
  "status",
  "created by",
  "updated on",
  // "linked",
  "quick actions",
];
export const BL = [
  {
    id: "ca571835-35e0-41bd-9a72-ff836170991a",
    name: "software engineer",
    color: "green text-white",
    created_at: "2022-07-18T05:20:44Z",
    updated_at: "2022-07-18T05:20:44Z",
    opening_id: "460aec39-bd52-4191-b5d7-cc05af6a1123",
    socket_client_id: "",
    applicant_id: "00000000-0000-0000-0000-000000000000",
  },
  {
    id: "b4ef0923-20b9-462c-a456-d398eae2fd0d",
    name: "devops engineer",
    color: "blue text-white",
    created_at: "2022-07-20T04:59:24Z",
    updated_at: "2022-07-20T04:59:24Z",
    opening_id: "460aec39-bd52-4191-b5d7-cc05af6a1123",
    socket_client_id: "",
    applicant_id: "00000000-0000-0000-0000-000000000000",
  },
  {
    id: "d55c23f2-b401-4f08-8305-4f67efcda1c2",
    name: "frontend engineer",
    color: "orange text-white",
    created_at: "2022-07-20T04:59:35Z",
    updated_at: "2022-07-20T04:59:35Z",
    opening_id: "460aec39-bd52-4191-b5d7-cc05af6a1123",
    socket_client_id: "",
    applicant_id: "00000000-0000-0000-0000-000000000000",
  },
  {
    id: "df37b9a8-f91c-4218-9d17-6ce505b9e27c",
    name: "backend engineer",
    color: "magenta text-white",
    created_at: "2022-07-20T04:59:41Z",
    updated_at: "2022-07-20T04:59:41Z",
    opening_id: "460aec39-bd52-4191-b5d7-cc05af6a1123",
    socket_client_id: "",
    applicant_id: "00000000-0000-0000-0000-000000000000",
  },
  {
    id: "6f48d643-6ad5-4242-8a95-dd5bfe65fe17",
    name: "ux engineer",
    color: "teal text-white",
    created_at: "2022-07-20T04:59:46Z",
    updated_at: "2022-07-20T04:59:46Z",
    opening_id: "460aec39-bd52-4191-b5d7-cc05af6a1123",
    socket_client_id: "",
    applicant_id: "00000000-0000-0000-0000-000000000000",
  },
];

export const application_roles = [
  "OWNER",
  "ADMIN",
  "HR",
  "HM",
  "INTERVIEWER",
  "GUEST",
  "TALENT PARTNER",
];
export type customRoles = {
  id: string;
  name: string;
  description: string;
};
export type cTools = {
  id: string;
  name: string;
  image: string;
  value: boolean;
  active: boolean;
};
export const c_tools: cTools[] = [
  {
    id: "zoom",
    name: "Connect Zoom Video Meeting",
    image: zoom,
    value: false,
    active: false,
  },
  {
    id: "meet",
    name: "Add Google meet Video Conferencing",
    image: meet,
    value: false,
    active: false,
  },
];

export const board_role: Role[] = [
  {
      "description": "Can view, edit all resources and settings",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
      "name": "Admin"
  },
  {
      "description": "Can only view,comments all public and member only resources",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
      "name": "Commentor"
  },
  {
      "description": "Can view, edit all public and member only resources",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
    "name": "Member"
  },
  {
      "description": "Can view, edit and delete all resources and settings",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
      "name": "Owner"
  }
];
export const boardVisibilityActions: customRoles[] = [
  {
    id: "",
    name: PUBLIC,
    description: "All members of organisation can see this board",
  },
  {
    id: "",
    name: MEMBER_ONLY,
    description: "Only board members can see this board.",
  },
];
export const cardVisibilityActions: customRoles[] = [
  {
    id: "",
    name: PUBLIC,
    description: "All members of board can see this card",
  },
  {
    id: "",
    name: MEMBER_ONLY,
    description: "Only card members can see this card.",
  },
];
export const INCOMING = "Incoming";
export const HISTORY = "History";
export const PREVIEW = "Preview";

//
export const CANDIDATES = "candidates";
export const REVIEWS = "reviews";

export const CLOSE_REVIEW_CONFIRMATION_MSG =
  "this action will close present review for all members.";
export const CLOSE_REVIEWS_CONFIRMATION_MSG =
  "this action will close all present reviews for all members.";



// email

export const TMAIL_MENU: {} = {
  inbox: {
    active: true,
    icon: <Inbox />,
  },
  sent: {
    active: false,
    icon: <Sent />,
  },
  // uncategorised: {
  //   active: false,
  //   icon: <Uncategorised />

  // },
  // important: {
  //   active: false,
  //   icon: <Important />

  // },
};

//domain tags
export const DOMAIN_TAGS = ["jobs", "careers", "hiring", "recruitment"];
export const DEFAULT_DOMAIN = ["Tacitmail", "custom"];
export const TACITMAIL_DOMAIN = "tacitmail.com";
export const TACITMAIL = "Tacitmail";
export const INBOX = "Inbox";

//activity api modalname
export const MODEL_BOARD = "boards";

export type CompanyHeader = {
  view: string;
  name: string;
  type: string;
  field?: string; // Optional field
};

export const COMPANY_HEADERS: CompanyHeader[] = [{
  view: "Workspace name",
  name: "name",
  type: "string",
}, 
  {
    view: "Manage by",
    name: "members",
    type: "array",
  },
  // {
  //   view: "contact person email",
  //   name: "contact_persons",
  //   type: "array",
  //   field: "email"
  // }, {
  //   view: "contact person phone",
  //   name: "contact_persons",
  //   type: "array",
  //   field: "phone"
  // }
];
export const AiButtonColors = ["#0D748B", "#00C89A"];


export const jdInfo = {
  prePublish: `Before publishing the job description,
  ensure all fields are accurately filled and review for errors.
  Once published, the job will be live and you'll receive a shareable URL`,
  aiInfo: `The information you provide will only be used for this job
  description and will not impact future work.`,
  aiWarning: `The AI-generated content may be incorrect or inappropriate; review before use.`
};



export const advanceFormDesignComponent = [
  {
    name: "Heading",
    type: "heading",
    secret: false,
    label: "Heading",
    size: "default",
    subheading: "subheading",
  },
  {
    name: "Divider",
    type: "divider",
    secret: false,
    label: "Divider",
    style: "solid",
    height: 1,
  },
  {
    name: "EEO",
    type: "EEO",
    secret: false,
    label: "EEO",
  },
  {
    name: "File Upload",
    type: "file",
    secret: false,
    label: "Add attachment",
  },
  // {
  //   name: "Footer",
  //   type: "footer",
  //   secret: false,
  //   label: "Footer",
  // },
  {
    name: "Terms and conditions",
    type: "TermsAndConditions",
    secret: false,
    label: "Terms and conditions",
    isRequired: true,
    link: "#"
  },
];

export const REQUIRED_FIELD_OF_DATABASE = [
  "first_name",
  "last_name",
  "email",
  "button",
];

export const DIVIDER_STYLE: string[] = ["solid", "dotted", "dashed"];
export const HEADING_SIZE: string[] = ["default", "small"];
// export const LOGO_ALIGNMENT: string[] = [
//   "left", "center", "right"
// ]
export const UNSAVED_WARNING = "you have unsaved changes"
export const FORM_SETTING = "Form setting";
export const FIELD_SETTING = "Field setting";

export const SAVE_AS_DRAFT = "Save as a draft";
export const BACK = "Back";

export const PUBLISH = "publish";
export const PUBLISH_JOB = "publish job"
export const UNPUBLISH_JOB = "unpublish job";

export const UNPUBLISH = "unpublish";
export const DRAFT = "draft";
export const COPY_FORM_LINK = "copy form link";
export const seniorityLevels = [
  "Internship",
  "Entry Level",
  "Associate",
  "Mid-Senior Level",
  "Director",
  "Executive",
  "Not applicable",
];

export const JD_SETTING_INFO = "To access settings, please publish the job.";
export const requiredFieldsOfJD: { [key: string]: string } = {
  title: "Job title is required",
  country: "location is required",
  job_type: "Job type is required",
};
export const ADD_DEFAULT_REVIEWER_HEADING =
  "Incoming candidates will be send to these reviewers automatically if reviewers are assigned";
export const CURRENCY_TYPE = ["INR", "USD"];

/****************************login page content  *******************************/
export const LOGIN_PAGE_HEADING =
  "goodbye to the old, clunky and bloated recruitment tools";
export const LOGIN_PAGE_CONTENT = [
  {
    heading: "customizable candidate database",
    subheading: "Design your own candidate database make candidates searchable",
  },
  {
    heading: "collaborative review system",
    subheading: "Collaborate with your team to make informed decisions",
  },
  {
    heading: "one stop solution",
    subheading: "Say goodbye to your hassle of using multiple tools",
  },
];

export const LOGIN_PAGE_NAVIGATION = [
  { href: "https://www.tacitbase.com", label: "Home" },
  { href: "https://www.tacitbase.com/terms-of-use", label: "Terms" },
  { href: "https://support.tacitbase.com/", label: "Contact" },
];


//search collection
export const COLLECTION_TACITBASE = "tacitbase"; //global search that includes all services
export const COLLECTION_ATS = "ats"; // global search in ats services that includes all collection
export const COLLECTION_ATS_OPENING = "ats_openings"; // search in ats service that only include openings collection. value after "_" is collection(schema) name
export const COLLECTION_CDB = "candidates"; // global search in candidates services that includes all collections
export const COLLECTION_CDB_CANDIDATES = "candidates_candidates"; // global search in candidates services that includes all collections
export const Visibility = [
  {
    value: PUBLIC,
    label: <Text>Public</Text>,
  },
  {
    value: MEMBER_ONLY,
    label: <Text>Member only</Text>,
  },
];
export const WorkspaceType = [
  {
    value: "Operations",
    label: <Text>Operations</Text>,
  },
  {
    value: "Education",
    label: <Text>Education</Text>,
  },
  {
    value: "Marketing",
    label: <Text>Marketing</Text>,
  },
  {
    value: "Small buisiness",
    label: <Text>Small business</Text>,
  },
  {
    value: "Sales CRM",
    label: <Text>Sales CRM</Text>,
  },
  {
    value: "Engineering-IT",
    label: <Text>Engineering-IT</Text>,
  },
  {
    value: "Human Resource",
    label: <Text>Human Resource</Text>,
  },
  {
    value: "Other",
    label: <Text>Other</Text>,
  },
];

// Slot error msgs
export const invalidEndDate = "Please choose up to next 3 months.";
export const invalidOccurrences = "Please choose between range 1-90.";

//reset password link expire component heading
export const RESET_PWD_LINK_HEADING =
  "It seems like the password reset link you are trying to use has either expired or has already been used.";

export const RESET_PWD_LINK_ADDITIONAL_HEADING =
  "If you continue to experience issues or have any questions, please contact our support team for assistance.";

export const RESET_PASSWORD_HEADING = "Enter the email associated with your account,and we'll send an email with instructions to reset your password."

//Constant for date format used in antd datepicker

export const DateFormate="DD MM YYYY";

  // Define validation patterns
export const regexPatterns: { [key: string]: RegExp } =  {
  github: /^(https?:\/\/)?(www\.)?github\..*\/.*$/,
  figma: /^(https?:\/\/)?(www\.)?figma\..*\/.*$/,
  dribbble: /^(https?:\/\/)?(www\.)?dribbble\..*\/.*$/,
  leetcode: /^(https?:\/\/)?(www\.)?leetcode\..*\/.*$/,
  personal_blog: /^(https?:\/\/)?([a-zA-Z0-9_\-.]+\.)+[a-zA-Z]{2,6}(\/.*)?$/,
  behance: /^(https?:\/\/)?(www\.)?behance\..*\/.*$/,
  research_gate: /^(https?:\/\/)?(www\.)?researchgate\..*\/.*$/,
  stack_overflow: /^(https?:\/\/)?(www\.)?stackoverflow\..*\/.*$/,
  google_scholar:
    /^https:\/\/scholar\.google\.com\/citations\?hl=en&user=[A-Za-z0-9_-]+$/,
  email:
    /^[a-zA-Z0-9]+(?:[-._+][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/,
  linked_in: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
  twitter: /^(https?:\/\/)?(www\.)?x\.com\/.*$/,
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.*$/,
  youtube: /^(https?:\/\/)?(www\.)?youtube\.com\/.*$/,
  insta: /^(https?:\/\/)?(www\.)?instagram\.com\/.*$/,
  website: /^(https?:\/\/)?(www\.)?[^/]+\/?.*$/

};
export const EEO_STATEMENT1 = "We are committed to providing equal employment opportunities to all applicants and employees. We are dedicated to fostering an inclusive environment where everyone feels valued and respected. We do not discriminate on the basis of race, color, religion, sex (including pregnancy, gender identity, and sexual orientation), national origin, age, disability, genetic information, veteran status, or any other legally protected characteristic."
export const EEO_STATEMENT2 = " All employment decisions are based on qualifications, merit, and business needs. We believe in creating a diverse workforce that reflects the communities we serve and promotes innovation and creativity."
export const CREATE_JOB_POST = "Create a job post"
// ATS tabs
export const PoolRoute="pool";
export const TrackerRoute="tracker";
export const CalendarRoute="calendar";
export const JobDescriptionRoute="job-description";
export const FormRoute="form";
export const SettingsRoute = "settings"

//JD warning msg
export const MAX_EXPERIENCE_ERROR = "Experience cannot be more than 100 years."
export const GREATER_EXPERIENCE_ERROR = "Must be greater than experience From."
export const LESS_EXPERIENCE_ERROR = "Must be less than experience to."


//form submission warning msg
export const FORM_SUBMISSION_WARNING_MSG = "Please provide a response for this field."
export const URL_FORMATE = "Invalid url format."
export const EMAIL_FORMATE = "Invalid email format."


//JD form input label
export const company_name = "company name"
export const salary_range_from = "salary_range_from"
export const salary_range_to = "salary_range_to"
export const experience_level_from = "experience_level_from"
export const experience_level_to = "experience_level_to"
export const number_of_open_positions = "number_of_open_positions"

//JD input error msgs
export const compensation_error = "Compensation values should be in proper range."
export const experience_error = "Experience values should be in proper range."


export const jobTypeMapping: { [key: string]: string } = {
  fulltime: "Full Time",
  parttime: "Part Time",
  internship: "Internship"
}

export const currencyTypeMapping = {
  inr: "INR",
  usd: "USD"
}


export const memberTabsInSetting = {
  ACTIVE: { label: "All", key: "active" },
  MEMBERS: { label: "Members", key: "members" },
  GUEST: { label: "Guests", key: "guest" },
  AGENCIES: { label: "Agencies", key: "agency" },
  DEACTIVE: { label: "Deactive", key: "deactivate" },
  INVITED: { label: "Invited", key: "invited" }
};

export const UserType = {
  FULL: "full",
  AGENCY: "agency",
  GUEST: "guest"
}
// cancel warning
export const CANCEL_WARNING_HEADING = "Are you sure you want to cancel? You'll lose your current job post and will need to recreate it.This action is irreversible."


// job not available warning
export const JOB_NOT_AVAILABLE_HEADING = "We currently don&apos;t have any active job openings"

export const JOB_POST_CREATION = "Create a job post now and streamline the hiring process by collecting applications in one place."

// Save job post as draft
export const SAVE_DRAFT_SUCCESS = "You can edit or publish it from the Job Description tab inside the Job Tracker."

// job post publish
export const JOB_POST_PUBLISHED_SUCCESS = "Job posted on partner site will take at least 90 minutes to go live."

// job post archive
export const JOB_POST_ARCHIVED_SUCCESS = "Job post archived successfully."
// job post unarchive
export const JOB_POST_UNARCHIVED_SUCCESS = "Job post restored successfully.."




//add candidate msg
export const CANDIDATE_ADDED_SUCCESS = "Candidate added successfully."

export const CANDIDATES_ADDED_TO_TRACKER_SUCCESS = "Candidates added successfully to tracker."

export const CANDIDATE_ADDED_TO_TRACKER_SUCCESS = "Candidate added successfully to tracker."

export const CANDIDATES_SEND_TO_REVIEW_SUCCESS = "Candidates sent for review."

export const CANDIDATE_SEND_TO_REVIEW_SUCCESS = "Candidate sent for review."
export const REFERRAL="referral"
export const THIRD_PARTY="third party"
export const OTHER="other"
export const AGENCIES="agencies"


//Sourcing mediums
export const RESUME_MEDIUM="tacitbase_resume"
export const TACITBASEQA_MEDIUM="tacitbase_qa"

//sourcing  private mode 
export const PRIVATE_MODE="pvt"

export const NOT_AVAILABLE="Not available"


// Columns required for validation in tables after parsing the resume
export const CANDIDATE_REQUIRED_FIELD = [
  "first_name",
  "last_name",
  "email",
];




//Background color of side navbar

export const BG_COLOR_SIDE_NAV = "#FBFAFD";
// "#EFEFEF"
// "#F0EFFE"

export const BG_TRIGGER_COLOR="#4B48A5"


export const colors = {
  primary: '#4B48A5',
  secondary: '#F0EFFE',
  divider:'#ADB1B0',
};

export const DIVIDER_HEIGHT='16px';

export const buttonStyles = {
  text: {
    backgroundColor: colors.secondary,
    color: colors.primary,
  },
};

export const defaultIconStyle: React.CSSProperties = { color: '#4B48A5', fontSize: '16px' };

export const SLACK_BUTTON = "Connect slack"


//custom values react quill text editor
export const customFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
];

//custom modules react quill text editor
export const customModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    // ['clean']
  ],
};

//Constant for social media icons
export const SOCIAL_LINKS_ICON: Record<string, JSX.Element>={
  facebook: <Facebook/>,
  twitter: <Twitter/>,
  linked_in: <Linkedin/>,
  // whatsapp: <Whatsapp/>,
  github: <Github/>,
  figma: <Figma />,
  dribbble: <Dribbble />,
  leetcode: <Leetcode />,
  personal_blog: <PersonalBlog />,
  behance: <Behance />,
  research_gate: <ResearchGate />,
  google_scholar: <GoogleScholar />,
  stack_overflow: <StackOverflow />,
}

export const File_SIZE_LIMIT_MB= 10;
export const FILE_SIZE_LIMIT = (1048576 * File_SIZE_LIMIT_MB); // 1 MB in bytes


export const predefinedFields = [
  { name: "linked_in", type: "url", view: "LinkedIn",custom:false },
  { name: "github", type: "url", view: "GitHub",custom:false  },
  { name: "figma", type: "url", view: "Figma" ,custom:false },
  { name: "dribbble", type: "url", view: "Dribbble",custom:false  },
  { name: "leetcode", type: "url", view: "LeetCode",custom:false  },
  { name: "personal_blog", type: "url", view: "Personal blog" ,custom:false },
  { name: "behance", type: "url", view: "Behance",custom:false  },
  { name: "research_gate", type: "url", view: "ResearchGate",custom:false  },
  { name: "google_scholar", type: "url", view: "Google Scholar" ,custom:false },
  { name: "twitter", type: "url", view: "Twitter",custom:false  },
  { name: "stack_overflow", type: "url", view: "Stack Overflow",custom:false  },
  
];
export const BETA_PRODUCT = "Our resume relevancy feature is now in beta — give it a try and share your feedback with us!";
export const PAID_RELEVANCY_FEATURES = "AI Relevancy is a paid feature. Upgrade your plan for exclusive access to evaluate candidate resumes.";
export const  PoolFilters = [
  { id: 1, label: "Relevant" },
  { id: 2, label: "Irrelevant" },
];

export const SET_VIEW_TYPE = "SET_VIEW_TYPE";

export const timezoneSelectCustomStyle ={
  control: (base: any, state: any) => ({
    ...base,
    height: '32px', // Fixed height
    minHeight: '32px', // Ensuring minimum height
    width: '290px',
    border: state.isFocused ? '1px solid #4b48a5' : '1px solid #d9d9d9',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(75, 72, 165, 0.2)' : 'none',
    '&:hover': {
      borderColor: '#4b48a5',
    },
    padding: '0 4px', // Adjust padding to ensure content fits well
    fontSize: '14px',
    backgroundColor: state.isDisabled ? '#f5f5f5' : '#fff', 
    cursor: state.isDisabled ? 'not-allowed' : 'default',
   }),
    valueContainer: (base: any) => ({
    ...base,
    padding: '0 8px', // Padding for inner content
    height: '32px', // Ensure height matches
    display: 'flex',
    alignItems: 'center', // Vertically center content (like placeholder and selected value)
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#bfbfbf',
    display: 'flex',
    alignItems: 'center', // Vertically center the placeholder text
    height: '100%', // Ensure placeholder takes up full height of the container
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#333',
    display: 'flex',
    alignItems: 'center', // Vertically center the selected value
    height: '100%', // Take up full height to ensure proper centering
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '0', // Remove extra padding around the icon
    display: 'flex',
    alignItems: 'center', // Vertically center the dropdown icon
    height: '32px', // Match the height of the control
    color: '#bfbfbf',
    '&:hover': {
      color: '#4b48a5',
    },
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '32px', // Ensure indicator container matches the control height
    display: 'flex',
    alignItems: 'center', // Vertically center the entire indicator container
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '4px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f5f5f5' : '#fff',
    color: '#333',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  }),
}

export const customMenuStyle = {
  backgroundColor: "white",
  border: "1px solid white",
  padding: 0, // Remove padding
  margin: 0, // Remove margin
};

export const customMenuItemStyle = {
  paddingTop: 0, // Remove padding
  marginTop: 0, // Remove margin
  marginBottom: 0,
  paddingBottom: 0,
};

export const SEND_TO_REVIEW_LABEL_WARNING_MESSAGE = "Candidate is being sent for review without labels. Consider adding labels for context."
