import { LOGIN_TOKEN } from "../constant";

import { logoutChannel } from "../WSComponent";

  export const logoutFromAllTabs = () => {
    logoutChannel.postMessage("logout");
    localStorage.removeItem(LOGIN_TOKEN);
    localStorage.removeItem('onboarding')
    sessionStorage.removeItem("current_tab_application_token")
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/login`);
  }