import {
  // Import necessary actions from the opening action file
  addApplicant,
  addAttachments,
  addList,
  addNewOpeningFromWS,
  archiveList,
  archivedAllListApplicants,
  deleteAttachment,
  saveComment,
  sendBackArchivedItem,
  draggableList,
  moveListAction,
  moveListWS,
  setBoardBackgroundColor,
  updateApplicantArchiveStatus,
  updateListName,
  updateOpeningName,
  moveAllApplicants,
  sendBackArchivedList,
  editApplicantDesc,
  editName,
  draggableCard,
  moveApplicantWS,
  move,
  copyApplicantAction,
  copyApplicantWS,
  updateComment,
  saveBoardLabel,
  saveCardLabel,
  removeLabelFromCard,
  updateLabelState,
  updateAttachment,
  removeMemberFromCard,
  addMemberOnBoard,
  removeMemberFromOpening,
  addMemberOnCard,
  editRoleOfMember,
  update_current_org_creation_stages,
  addOrgMember,
  updateUserServices,
  addCommentReview,
  getActiveBoard,
} from "./redux/actions/opening/action";

interface WebSocketResponse {
  // Define the structure of WebSocket response
  type: string;
  action: string;
  service: string;
  model_id: string;
  model_name: string;
  date: string;
  data: any;
  updated_at: string;
  user_id: string;
  org_id: string;
  org_name: string;
}

type reduxDataType = {
  // Define the structure of Redux data
  loginUser: loginUser;
  websocket: wsAction;
  orgId: string;
  orgName: string;

  boards: OrganizedBoard;
  currentOpening: Opening;
  orgMembers: orgMember;

  currentApplicantId: string;
  boardList: InitLists;
  clientId: string | null;

  currentReviewId: string;
  // ws
  wsConnectionObject: WebSocket;
};

import { con } from "./index";
import {
  InitLists,
  Opening,
  OrganizedBoard,
  loginUser,
  orgMember,
  wsAction,
} from "./type/type";
import { COMMENT_TAG } from "./constant";

export function handleWebSocketResponse(
  response: WebSocketResponse,
  reduxData: reduxDataType
): void {
  // Handle different WebSocket responses based on the model_name  
  switch (response?.model_name) {
    case "board":
      handleBoardAction(response.action, response.data, reduxData);
      break;
    case "card":
      handleCardAction(response.action, response.data, reduxData);
      break;
    case "comment":
      handleCommentAction(response.action, response.data, reduxData);
      break;
    case "list":
      handleListAction(response.action, response.data, reduxData);
      break;
    case "organisation":
      handleOrgAction(response?.action, response?.data);
      break;
      case "review":
        handleReviewAction(response?.action, response?.data);
        break;
    case 'invite':
      handleMemberServiceAddAction(response?.action, response?.data)
      break;
    default:
      return;
  }
}
function handleReviewAction(action: string, data: any): void {
  switch (action) {
    case "addedComment":
      // Dispatch the addList action to Redux store
      con?.store?.dispatch(
        addCommentReview(data?.comment, COMMENT_TAG)

      );
      break;     
    default:
      return;
  }
}
function handleMemberServiceAddAction(action: string, data: any): void {
  switch (action) {
    case "authorisedServices":
      // Dispatch the addList action to Redux store
      con?.store?.dispatch(
        updateUserServices(data?.services)
      );
      break;
      
      case "invitationAccepted":
        // Dispatch the addList action to Redux store
        con?.store?.dispatch(
          addOrgMember(data?.invitation.user[0])
        );
        break;
    default:
      return;
  }
}
function handleOrgAction(action: string, data: any): void {
  switch (action) {
    case "setupStages":
      // Dispatch the addList action to Redux store
      con?.store?.dispatch(
        update_current_org_creation_stages(data?.organisation.setup_stages)
      );
      break;
    default:
      return;
  }
}

function handleListAction(
  action: string,
  data: any,
  reduxData: reduxDataType
): void {
  // Handle different actions for lists
  switch (action) {
    case "create":
      // Dispatch the addList action to Redux store
      data.list.socket_client_id != reduxData.clientId &&
        con.store.dispatch(addList(data.list));
      break;
    case "movedAllApplicant":
      // Dispatch the moveAllApplicants action to Redux store
      data.move_list_applicants.socket_client_id != reduxData.clientId &&
        con.store.dispatch(moveAllApplicants(data.move_list_applicants));
      break;
    case "archived":
      // Handle the archived or sent back archived list action
      if (data.list.archive) {
        data.list.socket_client_id != reduxData.clientId &&
          con.store.dispatch(archiveList(data.list));
      } else {
        data.list.socket_client_id != reduxData.clientId &&
          con.store.dispatch(sendBackArchivedList(data.list));
      }
      // Dispatch the updateListName action to Redux store
      data.list.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateListName(data.list));
      break;
    case "updateName":
      // Dispatch the updateListName action to Redux store
      data.list.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateListName(data.list));
      break;
    case "archivedAllApplicant":
      // Dispatch the archivedAllListApplicants action to Redux store
      data.list.socket_client_id != reduxData.clientId &&
        con.store.dispatch(
          archivedAllListApplicants(data.list.id, data.list.opening_id)
        );
      break;
    case "move":
      // Handle different list move actions
      if (data.move_list.socket_client_id != reduxData.clientId) {
        if (data.move_list.type === "DRAG") {
          // Dispatch the draggableList action to Redux store
          const drag = {
            draggableId: data.move_list.src_list.id,
            type: "list",
            source: {
              index: data.move_list.src_list.position,
              droppableId: "all-lists",
            },
            destination: {
              droppableId: "all-lists",
              index: data.move_list.dst_list.position,
            },
          };
          con.store.dispatch(draggableList(drag));
        }
      } else {
        if (data.move_list.destination === "move_to_same_opening") {
          // Dispatch the moveListAction action to Redux store
          data.move_list.socket_client_id != reduxData.clientId &&
            con.store.dispatch(
              moveListAction(data.move_list, data.move_list.position)
            );
        }
        if (data.move_list.destination === "move_to_another_opening") {
          // Dispatch the moveListWS action to Redux store
          data.move_list.socket_client_id != reduxData.clientId &&
            con.store.dispatch(
              moveListWS(data.move_list, data.move_list.position)
            );
        }
      }
      break;
    default:
      return;
  }
}

function handleBoardAction(
  action: string,
  data: any,
  reduxData: reduxDataType
): void {
  // Handle different actions for boards
  switch (action) {
    case "updateMemberAccess":
      // Dispatch the updateAccess action to Redux store
      data.label.socket_client_id != reduxData.clientId &&
        con.store.dispatch(editRoleOfMember(data.access.id, data.access.access,data.access.opening_id));
      break;
    case "addLabel":
      // Dispatch the saveBoardLabel action to Redux store
      data.label.socket_client_id != reduxData.clientId &&
        con.store.dispatch(saveBoardLabel(data.label, data.label.opening_id));
      break;
    case "create":
      // Dispatch the addNewOpeningFromWS action to Redux store
      data.opening.socket_client_id != reduxData.clientId &&
        con.store.dispatch(addNewOpeningFromWS(data.opening));
      break;
    case "updateName":
      // Dispatch the updateOpeningName action to Redux store
      data.opening.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateOpeningName(data.opening));
      break;
    case "updateBackground":
      // Dispatch the setBoardBackgroundColor action to Redux store
      data.opening.socket_client_id != reduxData.clientId &&
        con.store.dispatch(setBoardBackgroundColor(data.opening));
      break;
    case "updateLabel":
      // Dispatch the updateLabelState action to Redux store
      data.applicant_label.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateLabelState(data.applicant_label));
      break;
    case "memberAdded":
      // Add a member to the opening and dispatch actions
      // if (
      //   !Object?.keys(reduxData?.boards?.pageSection?.openings)?.includes(
      //     data?.members[0]?.opening_id
      //   )
      // ) {
      //   getBoardByid(data?.members[0]?.opening_id)?.then((opening: Opening) => {
      //     data?.members[0]?.socket_client_id != reduxData?.clientId &&
      //       con?.store?.dispatch(addNewOpening(opening));
      //   });
      // }
      data?.members[0]?.socket_client_id != reduxData?.clientId &&
        con?.store?.dispatch(
          addMemberOnBoard(data?.members, data?.members[0]?.opening_id)
        );
      break;
    case "memberRemoved":
      if (reduxData.currentOpening.id == data.members.opening_id) {
        data.members.socket_client_id != reduxData.clientId &&
          con.store.dispatch(
            removeMemberFromOpening(data.members.id, data.members.opening_id)
          );
      }
      break;
    case "subscriptions":
      con.store.dispatch(
        getActiveBoard(data?.subscriptions?.subscriptions?.job_board)
      );
      break;
  
    default:
      return;
  }
}

function handleCardAction(
  action: string,
  data: any,
  reduxData: reduxDataType
): void {
  // Handle different actions for cards
  switch (action) {
    case "addedAttachment":
      // Dispatch the addAttachments action to Redux store
      data.attachment.socket_client_id != reduxData.clientId &&
        con.store.dispatch(
          addAttachments(data.attachment, data.attachment.list_id)
        );
      break;
    case "removedAttachment":
      // Dispatch the deleteAttachment action to Redux store
      data.deleted_attachment.socket_client_id != reduxData.clientId &&
        con.store.dispatch(
          deleteAttachment(
            data.deleted_attachment,
            data.deleted_attachment.list_id
          )
        );
      break;
    case "updateAttachment":
      // Dispatch the updateAttachment action to Redux store
      data.attachment.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateAttachment(data.attachment));
      break;
    case "removeLabel":
      // Dispatch the removeLabelFromCard action to Redux store
      data.applicant_label.socket_client_id != reduxData.clientId &&
        con.store.dispatch(
          removeLabelFromCard(
            data.applicant_label.label_id,
            data.applicant_label.applicant_id,
            data.applicant_label.list_id
          )
        );
      break;
    case "updateLabel":
      // Dispatch the updateLabelState action to Redux store
      data.applicant_label.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateLabelState(data.applicant_label));
      break;
    case "addLabel":
      // Dispatch the saveCardLabel action to Redux store
      data.applicant_label.socket_client_id != reduxData.clientId &&
        con.store.dispatch(saveCardLabel(data.applicant_label));
      break;
    case "updateDescription":
      // Dispatch the editApplicantDesc action to Redux store
      data.card.socket_client_id != reduxData.clientId &&
        con.store.dispatch(editApplicantDesc(data.card));
      break;
    case "updateName":
      // Dispatch the editName action to Redux store
      data.card.socket_client_id != reduxData.clientId &&
        con.store.dispatch(editName(data.card));
      break;
    case "create":
      // Dispatch the addApplicant action to Redux store

      data.card.socket_client_id != reduxData.clientId &&
        con.store.dispatch(addApplicant(data.card));
      break;
    case "memberAdded":
      // Dispatch the addMemberOnCard action to Redux store
      data.applicant_members[0].socket_client_id != reduxData.clientId &&
        con.store.dispatch(
          addMemberOnCard(
            data.applicant_members,
            data.applicant_members[0].applicant_id
          )
        );
      break;
    case "memberRemoved":
      // Dispatch the removeMemberFromCard action to Redux store
      data.applicant_member.socket_client_id != reduxData.clientId &&
        con.store.dispatch(
          removeMemberFromCard(
            data.applicant_member.id,
            data.applicant_member.applicant_id
          )
        );
      break;
    case "copy":
      // Handle different card copy actions
      if (data.copy_applicant.destination === "copy_to_same_opening") {
        // Dispatch the copyApplicantAction action to Redux store
        data.copy_applicant.socket_client_id != reduxData.clientId &&
          con.store.dispatch(
            copyApplicantAction(
              data.copy_applicant,
              data.copy_applicant.position
            )
          );
      }
      if (data.copy_applicant.destination === "copy_to_another_opening") {
        // Dispatch the copyApplicantWS action to Redux store
        data.copy_applicant.socket_client_id != reduxData.clientId &&
          con.store.dispatch(copyApplicantWS(data.copy_applicant));
      }
      break;
    case "move":
      // Handle different card move actions
      if (data.card.type === "DRAG") {
        data.card.socket_client_id != reduxData.clientId &&
          con.store.dispatch(
            draggableCard({
              draggableId: data.card.src_applicant.id,
              source: {
                index: data.card.src_applicant.position,
                droppableId: data.card.from.list_id,
              },
              destination: {
                droppableId: data.card.dst_applicant.list_id,
                index: data.card.dst_applicant.position,
              },
            })
          );
      } else {
        if (data.card.destination === "move_to_same_opening") {
          // Dispatch the move action to Redux store
          data.card.socket_client_id != reduxData.clientId &&
            con.store.dispatch(
              move(data.card, data.card.from.list_id, data.card.position)
            );
        }
        if (data.card.destination === "move_to_another_opening") {
          // Dispatch the moveApplicantWS action to Redux store
          data.card.socket_client_id != reduxData.clientId &&
            con.store.dispatch(moveApplicantWS(data.card));
        }
      }
      break;
    case "archived":
      // Handle archived or sent back archived item action
      if (data.card.archive) {
        // Dispatch the updateApplicantArchiveStatus action to Redux store
        data.card.socket_client_id != reduxData.clientId &&
          con.store.dispatch(updateApplicantArchiveStatus(data.card));
      } else {
        // Dispatch the sendBackArchivedItem action to Redux store
        data.card.socket_client_id != reduxData.clientId &&
          con.store.dispatch(sendBackArchivedItem(data.card));
      }
      break;
    default:
      return;
  }
}

function handleCommentAction(
  action: string,
  data: any,
  reduxData: reduxDataType
): void {
  // Handle different actions for comments
  switch (action) {
    case "addedComment":
      // Dispatch the saveComment action to Redux store
      data.comment.socket_client_id != reduxData.clientId &&
        con.store.dispatch(saveComment(data.comment, COMMENT_TAG));
      break;
    case "updateComment":
      // Dispatch the updateComment action to Redux store
      data.comment.socket_client_id != reduxData.clientId &&
        con.store.dispatch(updateComment(data.comment));
      break;
    default:
      return;
  }
}
